<template>
  <Modal closePosition="hidden">
    <div class="root">
      <div class="p-6">
        <h2 class="font-h font-semibold text-2xl text-primary">
          File too long
        </h2>

        <div class="mt-2.5 text-sm text-primary">
          Can't add the file as it exceeds the maximum length of a Spext Doc - 2 hours.
        </div>

        <div class="mt-4 flex items-center justify-end">
          <Button size="small" variant="secondary" :onclick="showChat" classname="mr-2.5">
            Chat with Support
          </Button>
          <Button size="small" :onClick="close">
            Okay
          </Button>
        </div>
      </div>

      <div class="px-6 py-2 bg-primary-100 rounded-br-lg rounded-bl-lg flex items-center">
        <div class="mt-1 text-xs text-primary-700 font-light">
          <b>Tip:</b> Instead of adding entire files, copy-paste only the required sections to keep the length under 2 hours.
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'

export default {
  components: {
    Modal,
    Button
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    close: function() {
      this.closeModal()
    },
    showChat: function() {
      window.Intercom('showNewMessage', 'Unable to add big files')
      this.closeModal()
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 560px;
}
</style>
